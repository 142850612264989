import { withInstall } from 'framework/utils'
import basicArrow from './src/BasicArrow.vue'
import basicTitle from './src/BasicTitle.vue'
import basicHelp from './src/BasicHelp.vue'
import empty from './src/Empty.vue'
export { useAppProviderContext } from './src/useAppContext'

export const BasicArrow = withInstall(basicArrow)
export const BasicTitle = withInstall(basicTitle)
export const BasicHelp = withInstall(basicHelp)
export const Empty = withInstall(empty)

