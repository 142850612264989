<template>
  <div>
    <slot name="insertFooter"></slot>
    <a-button v-bind="cancelButtonProps" @click="handleCancel" v-if="showCancelBtn">
      {{ props.cancelText !== 'common.cancelText' ? props.cancelText : '取消' }}
    </a-button>
    <slot name="centerFooter"></slot>
    <a-button
      :type="okType"
      @click="handleOk"
      :loading="confirmLoading"
      v-bind="okButtonProps"
      v-if="showOkBtn"
    >
      {{ props.okText !== 'common.okText' ? props.okText : '确定' }}
    </a-button>
    <slot name="appendFooter"></slot>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'

  import { basicProps } from '../props'
  export default defineComponent({
    name: 'BasicModalFooter',
    props: basicProps,
    emits: ['ok', 'cancel'],
    setup(props, { emit }) {
      function handleOk(e: Event) {
        emit('ok', e)
      }

      function handleCancel(e: Event) {
        emit('cancel', e)
      }

      return { handleOk, handleCancel, props }
    },
  })
</script>
