<template>
  <div :class="`${prefixCls}`">
    <img
      src="https://dev-1304173006.cos.accelerate.myqcloud.com/workbench/images/1680143975099/21871103/114381693_rz.jpg"
      alt=""
      style="width: 322px"
    />
  </div>
</template>
<script lang="ts" setup>
  import { useDesign } from 'framework/hooks/web/useDesign'

  const props = defineProps({
    text: { type: String },
    imgSrc: { type: String },
    width: { type: String },
  })

  const { prefixCls } = useDesign('empty-wrap')
</script>
<style lang="less" scoped>
  @prefix-cls: ~'@{namespace}-empty-wrap';
  .@{prefix-cls} {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
    }
  }
</style>
